<template>

  <div class="flex justify-end w-full my-5" v-if="errorsList.items.length > 0">
    <!--- validation is displayed here, userform is name of the form--->
    <div class="valiadtion-popup py-10">
      <div class="w-full flex">
        <img :src="warningIcon"  class=" warning-icon"/>

        <p class="mb-0 warningText">Please populate all mandatory fields before proceeding.</p>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Popup",
  props: {
    formName: {
      type: String,
      required: true
    },
    errorsList: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      warningIcon: require("@/assets/images/icons/alert-circle.svg")
    }
  },
  methods:{
    getErrorFieldId(id){
      return '#'+id
    }
  }

}
</script>